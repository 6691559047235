<template>
  <div class="q-py-lg q-px-md">
    <div class="row q-mb-md q-col-gutter-md">
      <section class="col-3 flex-grow-1">
        <q-select
          v-model="filter.status"
          :options="statusItems"
          dense
          clearable
          outlined
          map-options
          emit-value
          label="Status"
        />
      </section>

      <section class="col-3 flex-grow-1">
        <q-select
          v-model="filter.account"
          :options="accountItems"
          multiple
          dense
          clearable
          outlined
          emit-value
          map-options
          use-input
          use-chips
          option-value="id"
          option-label="name"
          label="Account Name"
          @input-value="accounts.searchText = $event"
        />
      </section>

      <section v-if="accessFilterAgent" class="col-3 flex-grow-1">
        <q-select
          v-model="filter.agent"
          :options="agentItems"
          :loading="agents.loading"
          :input-debounce="0"
          multiple
          dense
          outlined
          use-chips
          use-input
          clearable
          map-options
          emit-value
          option-label="username"
          option-value="id"
          label="Agent"
          class="q-mb-md"
          @input-value="agents.searchText = $event"
        />
      </section>

      <section class="col-3 flex-grow-1">
        <q-select
          v-model="filter.methodType"
          :options="methodTypeItems"
          :loading="methodTypes.loading"
          :input-debounce="0"
          multiple
          dense
          outlined
          use-chips
          use-input
          clearable
          map-options
          emit-value
          option-label="name"
          option-value="id"
          label="Method"
          class="q-mb-md"
          @input-value="methodTypes.searchText = $event"
        />
      </section>
    </div>

    <q-btn
      v-if="accessCreateAccount"
      unelevated
      icon="mdi-plus"
      color="primary"
      label="Create account"
      class="flex q-ml-auto q-mb-md"
      @click="toggleShowCreateAccountForm(true)"
    />

    <q-table
      :columns="tableColumns"
      :loading="tableLoading"
      :data="tableData"
      :rows-per-page-options="[40, 80, 120]"
      title="Accounts"
    >
      <template #header="props">
        <q-tr :props="props">
          <q-th auto-width />

          <q-th v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.label }}
          </q-th>
        </q-tr>
      </template>

      <template #body="props">
        <q-tr :props="props">
          <q-td auto-width>
            <q-btn
              v-if="props.row.requisites.length > 0"
              :icon="!props.expand ? 'mdi-chevron-down' : 'mdi-chevron-up'"
              outline
              unelevated
              round
              dense
              size="sm"
              color="primary"
              @click="props.expand = !props.expand"
            />
          </q-td>

          <q-td v-for="col in props.cols" :key="col.name" :props="props">
            <q-badge
              v-if="col.name === 'agent'"
              :label="props.row.agent.username"
              outline
              color="indigo"
            />

            <q-badge
              v-else-if="col.name === 'methodType'"
              :label="props.row.methodType.name"
              outline
              color="indigo"
            />

            <DSwitch
              v-else-if="col.name === 'enable'"
              :value="props.row.enable"
              :disable="!accessEnableAccount && !accessDisableAccount"
              :disable-true-value="!accessDisableAccount"
              :disable-false-value="!accessEnableAccount"
              dense
              color="green"
              @input="
                toggleRefillAccountEnable({ enable: $event, id: props.row.id })
              "
            />

            <q-btn
              v-else-if="col.name === 'actions'"
              :to="{
                name: 'AccountEdit',
                params: {
                  id: props.row.id,
                },
              }"
              unelevated
              round
              color="primary"
              size="xs"
              icon="mdi-pencil"
            />

            <template v-else>
              {{ col.value }}
            </template>
          </q-td>
        </q-tr>

        <q-tr v-if="props.expand" :props="props">
          <q-td colspan="100%">
            <q-table
              :columns="[
                {
                  name: 'id',
                  field: 'id',
                  label: 'ID',
                  align: 'left',
                },
                {
                  field: 'properties',
                  name: 'properties',
                  label: 'Properties',
                  align: 'left',
                },
                {
                  field: 'payload',
                  name: 'payload',
                  label: 'Payload',
                  align: 'left',
                },
                {
                  field: 'enable',
                  name: 'enable',
                  label: 'Enable',
                  align: 'left',
                },
                {
                  field: 'enable',
                  name: 'enable',
                  label: 'Enable',
                  align: 'left',
                },
              ]"
              :rows-per-page-options="[2, 4]"
              :data="props.row.requisites"
              title="Requisites"
            >
              <template #body-cell-properties="props">
                <q-card
                  v-for="(item, itemIndex) in props.row.properties"
                  :key="itemIndex"
                >
                  <q-card-section>
                    <p class="text-weight-bold q-mb-sm">Key</p>
                    <q-input
                      :value="item.key"
                      readonly
                      outlined
                      dense
                      class="q-mb-md"
                    />

                    <p class="text-weight-bold q-mb-sm">Value</p>
                    <q-input :value="item.value" readonly outlined dense />
                  </q-card-section>
                </q-card>
              </template>

              <template #body-cell-payload="props">
                <q-card
                  v-for="(item, itemIndex) in props.row.payload"
                  :key="itemIndex"
                >
                  <q-card-section>
                    <p class="text-weight-bold q-mb-sm">Key</p>
                    <q-input
                      :value="item.key"
                      readonly
                      outlined
                      dense
                      class="q-mb-md"
                    />

                    <p class="text-weight-bold q-mb-sm">Value</p>
                    <q-input :value="item.value" readonly outlined dense />
                  </q-card-section>
                </q-card>
              </template>

              <template #body-cell-enable="requisitesProps">
                <q-td :props="requisitesProps">
                  <DSwitch
                    :value="requisitesProps.row.enable"
                    :disable="
                      !accessEnableRequisites && !accessDisableRequisites
                    "
                    :disable-true-value="!accessDisableRequisites"
                    :disable-false-value="!accessEnableRequisites"
                    color="green"
                    @input="
                      toggleRefillAccountRequisiteEnable({
                        enable: $event,
                        id: requisitesProps.row.id,
                      })
                    "
                  />
                </q-td>
              </template>
            </q-table>
          </q-td>
        </q-tr>
      </template>
    </q-table>

    <q-dialog
      v-if="isShowCreateAccountForm"
      v-model="isShowCreateAccountForm"
      persistent
    >
      <CreateAccountForm
        @success="onCreatedAccount"
        @cancel="toggleShowCreateAccountForm(false)"
      />
    </q-dialog>
  </div>
</template>

<script>
import { DSwitch } from '@/features/switch';
import { accountController } from '@/shared/api';
import { ROLES } from '@/shared/constants';
import { authUtils } from '@/shared/utils';

export default {
  name: 'AccountsList',
  components: {
    CreateAccountForm: () => import('./components/create-account-form'),
    DSwitch,
  },
  data() {
    return {
      isShowCreateAccountForm: false,
      accounts: {
        list: [],
        loading: false,
        searchText: '',
      },
      methodTypes: {
        list: [],
        loading: false,
        searchText: '',
      },
      agents: {
        list: [],
        loading: false,
        searchText: '',
      },
      filter: {
        id: 0,
        account: null,
        status: null,
        agent: null,
        methodType: null,
      },
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          name: 'id',
          field: 'id',
          label: 'ID',
          align: 'left',
        },
        {
          name: 'name',
          field: 'name',
          label: 'Account name',
          align: 'left',
        },
        {
          name: 'agent',
          field: 'agent',
          label: 'Agent name',
          align: 'left',
        },
        {
          name: 'methodType',
          field: 'methodType',
          label: 'Method',
          align: 'left',
        },
        {
          name: 'enable',
          field: 'enable',
          label: 'Enable',
          align: 'left',
        },
        {
          name: 'actions',
          label: '',
          align: 'right',
        },
      ];
    },
    tableData() {
      const { account, status, agent, methodType } = this.filter;

      return this.accounts.list.filter((item) => {
        if (
          Array.isArray(account) &&
          account.length > 0 &&
          !account.includes(item.id)
        ) {
          return false;
        }

        if (
          Array.isArray(agent) &&
          agent.length > 0 &&
          !agent.includes(item.agent.id)
        ) {
          return false;
        }

        if (
          Array.isArray(methodType) &&
          methodType.length > 0 &&
          !methodType.includes(item.methodType.id)
        ) {
          return false;
        }

        return status === null || status === +item.enable;
      });
    },
    tableLoading() {
      return this.accounts.loading;
    },
    statusItems() {
      return [
        {
          label: 'Enable',
          value: 1,
        },
        {
          label: 'Disable',
          value: 0,
        },
      ];
    },
    methodTypeItems() {
      const searchText = this.methodTypes.searchText.trim().toLowerCase();

      if (searchText) {
        return Object.freeze(
          this.methodTypes.list.filter((item) =>
            item.name.toLowerCase().includes(searchText)
          )
        );
      }

      return this.methodTypes.list;
    },
    agentItems() {
      const searchText = this.agents.searchText.trim().toLowerCase();

      if (searchText) {
        return Object.freeze(
          this.agents.list.filter((item) =>
            item.username.toLowerCase().includes(searchText)
          )
        );
      }

      return this.agents.list;
    },
    accountItems() {
      const searchText = this.accounts.searchText.trim().toLowerCase();

      if (searchText) {
        return Object.freeze(
          this.accounts.list.filter((item) =>
            item.name.toLowerCase().includes(searchText)
          )
        );
      }

      return this.accounts.list;
    },
    accessFilterAgent() {
      return authUtils.checkRoles([
        ROLES.ROLE_ADMIN,
        ROLES.ROLE_SUPERVISOR,
        ROLES.ROLE_SUPPORT_OFFICER,
      ]);
    },
    accessCreateAccount() {
      return authUtils.checkRoles([ROLES.ROLE_ADMIN, ROLES.ROLE_SUPERVISOR]);
    },
    accessEnableAccount() {
      return authUtils.checkRoles([ROLES.ROLE_ADMIN, ROLES.ROLE_SUPERVISOR]);
    },
    accessDisableAccount() {
      return authUtils.checkRoles([
        ROLES.ROLE_ADMIN,
        ROLES.ROLE_SUPERVISOR,
        ROLES.ROLE_AGENT,
      ]);
    },
    accessEnableRequisites() {
      return authUtils.checkRoles([ROLES.ROLE_ADMIN, ROLES.ROLE_SUPERVISOR]);
    },
    accessDisableRequisites() {
      return authUtils.checkRoles([
        ROLES.ROLE_ADMIN,
        ROLES.ROLE_SUPERVISOR,
        ROLES.ROLE_AGENT,
      ]);
    },
  },
  methods: {
    toggleShowCreateAccountForm(showed = !this.isShowCreateAccountForm) {
      this.isShowCreateAccountForm = showed;
    },
    toggleRefillAccountEnable({ id, enable }) {
      accountController.updateRefillAccountEnable({ id, enable });
    },
    toggleRefillAccountRequisiteEnable({ id, enable }) {
      accountController.updateRefillAccountRequisiteEnable({ id, enable });
    },
    onCreatedAccount() {
      this.toggleShowCreateAccountForm(false);
      this.fetchAccounts();
    },
    async fetchMethodTypes() {
      this.methodTypes.loading = true;

      try {
        const data = await accountController.fetchRefillAccountMethods();

        this.methodTypes.list = Object.freeze(data);
        this.methodTypes.loading = false;
      } catch (error) {
        this.methodTypes.loading = false;
      }
    },
    async fetchAccounts() {
      if (this.accounts.loading) return;

      this.accounts.loading = true;

      try {
        const accounts = await accountController.findRefillAccounts({
          limit: {
            lastId: 0,
            maxResults: 1000,
          },
        });

        this.accounts.loading = false;

        this.accounts.list = Object.freeze(accounts);
      } catch (error) {
        this.accounts.loading = false;
      }
    },
    async fetchAgents() {
      if (!this.accessFilterAgent) return;

      this.agents.loading = true;

      try {
        const data = await accountController.fetchRefillAccountAgents();

        this.agents.list = Object.freeze(data);
        this.agents.loading = false;
      } catch (error) {
        this.agents.loading = false;
      }
    },
  },
  mounted() {
    this.fetchMethodTypes();
    this.fetchAgents();
    this.fetchAccounts();
  },
};
</script>
