<template>
  <q-toggle
    v-model="localValue"
    v-bind="$attrs"
    v-on="$listeners"
    :disable="localDisable"
    :true-value="trueValue"
    :false-value="falseValue"
  />
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    trueValue: {
      type: [Boolean, String, Number],
      default: true,
    },
    falseValue: {
      type: [Boolean, String, Number],
      default: false,
    },
    disableTrueValue: {
      type: Boolean,
      default: false,
    },
    disableFalseValue: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  computed: {
    localDisable() {
      if (this.localValue === this.trueValue) {
        return this.disableTrueValue;
      }

      if (this.localValue === this.falseValue) {
        return this.disableFalseValue;
      }

      return this.disable;
    },
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>
